import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { servicesUrl } from './servicesUrl';
import { Observable } from 'rxjs';
import { Iadvertisement, Ilang, Inews, IOurTeam, Iproducts } from './model';

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  constructor(private http: HttpClient, private urlApi: servicesUrl) { }



  public getPost(check: string,PostID:string, Lang: string, FbrnId: string,Type:string,ParentId:string,PostName:string,Pno:string) {
    const bodyFormData = new FormData();
    bodyFormData.append("check",check);
    bodyFormData.append("Lang", Lang);
    bodyFormData.append("PostId",PostID)
    bodyFormData.append("FbrnId", FbrnId);
    bodyFormData.append("Type", Type);
    bodyFormData.append("ParentId", ParentId);
    bodyFormData.append("PostName", PostName);
    bodyFormData.append("Pno",Pno);
    return this.http.post(this.urlApi.url, bodyFormData).pipe();
  }

  // public getLangJson(lang:string):Observable<any>{
  //   return this.http.get('../../assets/i18n/'+lang+'.json')
  // }


  // public getLangIsMain() : Observable<Ilang[]>{
  //   return this.http.get<Ilang[]>(this.urlApi.url + '/languages/getLangIsMain');
  // }



  // public getLangWhereIdLang(id:string) : Observable<Ilang[]>{
  //   return this.http.get<Ilang[]>(this.urlApi.url + '/languages/'+ id );
  // }


  public sendMessage(check: string,LangId:string,Name:string,Email:string,Phone:string,MessageTitle:string,Message:string,FbrnId:string,FusrId:string,Fstate:string) {
    const formData = new FormData();
    formData.append('check', check);
    formData.append('LangId', LangId);
    formData.append('Name', Name);
    formData.append('Email', Email);
    formData.append('Phone', Phone);
    formData.append('MessageTitle', MessageTitle);
    formData.append('Message', Message);
    formData.append('FbrnId', FbrnId);
    formData.append('FusrId', FusrId);
    formData.append('Fstate', Fstate);
    return this.http.post(this.urlApi.url + '/contact_us/contactUsPost', formData);
  }
}



