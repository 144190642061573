import { Component } from '@angular/core';
import { HomeService } from './services/home.service';
import { servicesUrl } from './services/servicesUrl';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  year=new Date().getFullYear();
  load: boolean = false;
  logoheader: string = '';
  logofooter: string = '';
  address_: any;

  ph1: string = '';
  ph2: string = '';
  fb: string = '';
  linkedin: string = '';
  fb_footer: string = '';
  linkedin_footer: string = '';
  h_Note1: string = '';
  FooterDetails: string = '';

  arrayAboutUs: any;
  arrayResearchTopic: any;
  arrayPublicationCategroy: any;
  arrayProjectCategory: any;
  arrayTeamCategory: any;
  arrayCollaboratorsCategory: any;
  arrayVecancyCategory: any;
  imgUrls: any;

  constructor(
    private homeService_: HomeService,
    private imgUrlServ: servicesUrl
  ) {
   this.logoheader = '../assets/images/logo/logo-dark.png'
   this.logofooter = '../assets/images/logo/logo-light.png'
  }

  ngOnInit(): void {
  
    this.load = false;
    this.imgUrls = this.imgUrlServ.imgUrl;
    
    this.homeService_
    .getPost(
      'getPost',
      '',
      'en',
      '',
      'WS.Footer.Data',
      'NTROOT0',
      '',
      '1'
    )
    .subscribe((data) => {
      let arr = data as any[];
      this.logofooter = this.imgUrls + arr[0]['postImage'];
      this.FooterDetails = arr[0]['postTitle'];
      this.fb_footer = arr[0]['f_Facebook'];
      this.linkedin_footer = arr[0]['f_Linkedin'];

    });



    this.homeService_
      .getPost('getPost', '', 'en', '', 'WS.Header.Data', 'NTROOT0', '', '1')
      .subscribe((data) => {
        let arr = data as any[];
        // this.address_ = arr[0]['brnAddress']
        // this.brnAbout = arr[0]['brnAbout']
        this.logoheader = this.imgUrls + arr[0]['postImage'];
        this.h_Note1 = arr[0]['h_Note'];
        this.fb = arr[0]['h_Facebook'];
        this.linkedin = arr[0]['h_Linkedin'];
     
        this.homeService_
          .getPost('getPost', '', 'en', '', 'TeamCategory', 'NTROOT0', '', '-1')
          .subscribe((data) => {
            this.arrayTeamCategory = data;

            this.homeService_
              .getPost(
                'getPost',
                '',
                'en',
                '',
                'PublicationCategroy',
                'NTROOT0',
                '',
                '-1'
              )
              .subscribe((data) => {
                this.arrayPublicationCategroy = data;

             

                    this.homeService_
                      .getPost(
                        'getPost',
                        '',
                        'en',
                        '',
                        'ResearchTopicCategroy',
                        'NTROOT0',
                        '',
                        '-1'
                      )
                      .subscribe((data) => {
                        this.arrayResearchTopic = data;

                        this.homeService_
                          .getPost(
                            'getPost',
                            '',
                            'en',
                            '',
                            'ProjectCategory',
                            'NTROOT0',
                            '',
                            '-1'
                          )
                          .subscribe((data) => {
                            this.arrayProjectCategory = data;

                            this.homeService_
                              .getPost(
                                'getPost',
                                '',
                                'en',
                                '',
                                'CollaboratorsCategory',
                                'NTROOT0',
                                '',
                                '-1'
                              )
                              .subscribe((data) => {
                                this.arrayCollaboratorsCategory = data;

                                this.homeService_
                                  .getPost(
                                    'getPost',
                                    '',
                                    'en',
                                    '',
                                    'VecancyCategory',
                                    'NTROOT0',
                                    '',
                                    '-1'
                                  )
                                  .subscribe((data) => {
                                    this.arrayVecancyCategory = data;

                                    this.homeService_
                                      .getPost(
                                        'getPost',
                                        '',
                                        'en',
                                        '',
                                        'Home.About',
                                        'NTROOT0',
                                        '',
                                        '-1'
                                      )
                                      .subscribe((data) => {
                                        this.arrayAboutUs = data;
                                        setTimeout(() => {
                                          this.load = true;
                                        }, 100);
                                      });
                                  });
                              });
                          });
                      });
                  });
        
        
        });
      });
  }

  onActivate(event: any) {
    window.scroll(0, 0);
  }
}
