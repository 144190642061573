<!-- <div class="preloader" [hidden]="load">
    <div class="loading">
        <img src="../assets/images/loader/loader.svg" alt="">
    </div>
</div> -->
<app-loading [hidden]="load"></app-loading>
<header class="header header-light header-layout2">
    <div class="topbar d-none d-lg-block">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="d-flex align-items-center justify-content-between">
                        <ul class="topbar__contact d-flex flex-wrap list-unstyled mb-0">
                            <li>
                                <i class="icon-envelope"></i><a href="tel:This Web Site Under Construction "><span class="__cf_email__">{{h_Note1}}</span></a>
                            </li>
                        </ul>
                        <div class="d-flex">
                            <ul class="social-icons list-unstyled mb-0 mr-30">
                                <li><a target="_blank" href="{{fb}}"><i class="fab fa-facebook-f"></i></a></li>

                                <li><a target="_blank" href="{{linkedin}}"><i class="fab fa-linkedin-in"></i></a></li>
                            </ul>
                            <div class="dropdown dropdown__lang">
                                <button class="dropdown-toggle" id="langDrobdown" data-toggle="dropdown" aria-expanded="false">
                                    <img src="assets/images/flags/en.png" alt="en"><span>English</span>
                                </button>
                                <div class="dropdown-menu" aria-labelledby="langDrobdown">
                                    <a routerLink="/" class="dropdown-item">

                                        <img src="assets/images/flags/en.png" alt="en"><span>English</span>
                                    </a>
                                    <a class="dropdown-item" routerLink="Underway">

                                        <img src="assets/images/flags/tr.png" alt="tu"><span>Turkish</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <nav class="navbar navbar-expand-lg sticky-navbar shadow">
        <div class="container-fluid">
            <a class="navbar-brand" routerLink="/">
                <img src="{{logoheader}}" class="w-75" alt="logo">
            </a>
            <button class="navbar-toggler" type="button">
                <span class="menu-lines"><span></span></span>
            </button>
            <div class="collapse navbar-collapse" id="mainNavigation">
                <ul class="navbar-nav mr-auto ml-auto">

                    <li class="nav__item  has-dropdown">
                        <a routerLink="home" data-toggle="dropdown" class="dropdown-toggle nav__item-link  ">Home</a>

                        <ul class="dropdown-menu">
                            <li class="nav__item" style="padding: 0 10px;">
                                <a routerLink="/aboutUs">
                                    <i style="color: #019cce;font-size: 16px;margin-bottom: 10px;" class="fa fa-square-o"></i><span style="margin-left: 5px;color: #019cce;">All</span>
                                </a>
                            </li>
                            <li class="nav__item" style="padding: 0 10px;margin-top: 5px;" *ngFor="let item of arrayAboutUs">
                                <a routerLink="/aboutUs/{{item.postID}}">
                                    <i style="color: #019cce;font-size: 16px;margin-bottom: 10px;" class="fa fa-square-o"></i><span style="margin-left: 5px;color: #019cce;">{{item.postTitle}}</span>
                                </a>
                            </li>
                        </ul>
                    </li>
                    <li class="nav__item  has-dropdown">
                        <a  data-toggle="dropdown" class="dropdown-toggle nav__item-link">Research-Team</a>
                        <ul class="dropdown-menu">
                            <li class="nav__item" style="padding: 0 10px;">
                                <a routerLink="../our-team">
                                    <i style="color: #019cce;font-size: 16px;margin-bottom: 10px;" class="fa fa-square-o"></i><span style="margin-left: 5px;color: #019cce;">All Team</span>
                                </a>
                            </li>
                            <li class="nav__item" style="padding: 0 10px;" *ngFor="let item of arrayTeamCategory">
                                <a routerLink="../our-team/{{item.postID}}">
                                    <i style="color: #019cce;font-size: 16px;margin-bottom: 10px;" class="fa fa-square-o"></i><span style="margin-left: 5px;color: #019cce;">{{item.postTitle}}</span>
                                </a>
                            </li>
                        </ul>
                    </li>

                    <li class="nav__item  has-dropdown">
                        <a data-toggle="dropdown" class="dropdown-toggle nav__item-link"> Research
                            Projects</a>
                        <ul class="dropdown-menu">
                            <li class="nav__item" style="padding: 0 10px;">
                                <a routerLink="../researchProjects">
                                    <i style="color: #019cce;font-size: 16px;margin-bottom: 10px;" class="fa fa-square-o"></i><span style="margin-left: 5px;color: #019cce;">All Projects</span>
                                </a>
                            </li>
                            <li class="nav__item" style="padding: 0 10px;" *ngFor="let item of arrayProjectCategory">
                                <a routerLink="../researchProjects/{{item.postID}}">
                                    <i style="color: #019cce;font-size: 16px;margin-bottom: 10px;" class="fa fa-square-o"></i>
                                    <span style="margin-left: 5px;color: #019cce;">
                                      {{item.postTitle}} </span>
                                </a>
                            </li>

                        </ul>
                    </li>
                    <li class="nav__item  has-dropdown">
                        <a data-toggle="dropdown" class="dropdown-toggle nav__item-link">Research-Topics</a>
                        <ul class="dropdown-menu">
                            <li class="nav__item" style="padding: 0 10px;">
                                <a routerLink="../researchTopics">
                                    <i style="color: #019cce;font-size: 16px;margin-bottom: 10px;" class="fa fa-square-o"></i><span style="margin-left: 5px;color: #019cce;">All Research-Topics</span>
                                </a>
                            </li>
                            <li class="nav__item" style="padding: 0 10px;" *ngFor="let item of arrayResearchTopic">
                                <a routerLink="../researchTopics/{{item.postID}}">
                                    <i style="color: #019cce;font-size: 16px;margin-bottom: 10px;" class="fa fa-square-o"></i><span style="margin-left: 5px;color: #019cce;"> {{item.postTitle}}</span>
                                </a>
                            </li>

                        </ul>
                    </li>
                    <li class="nav__item  has-dropdown">
                        <a data-toggle="dropdown" class="dropdown-toggle nav__item-link">Publications</a>
                        <ul class="dropdown-menu">
                            <li class="nav__item" style="padding: 0 10px;">
                                <a routerLink="../publications">
                                    <i style="color: #019cce;font-size: 16px;margin-bottom: 10px;" class="fa fa-square-o"></i><span style="margin-left: 5px;color: #019cce;">All Publications</span>
                                </a>
                            </li>
                            <li class="nav__item" style="padding: 0 10px;" *ngFor="let item of arrayPublicationCategroy">
                                <a routerLink="../publications/{{item.postID}}/{{item.postTitle}}">
                                    <i style="color: #019cce;font-size: 16px;margin-bottom: 10px;" class="fa fa-square-o"></i><span style="margin-left: 5px;color: #019cce;">{{item.postTitle}}</span>
                                </a>
                            </li>
                        </ul>
                    </li>
                    <li class="nav__item  has-dropdown">
                        <a data-toggle="dropdown" class="dropdown-toggle nav__item-link">Collaborators</a>
                        <ul class="dropdown-menu">
                            <li class="nav__item" style="padding: 0 10px;">
                                <a routerLink="../collaborators">
                                    <i style="color: #019cce;font-size: 16px;margin-bottom: 10px;" class="fa fa-square-o"></i><span style="margin-left: 5px;color: #019cce;">All Collaborators</span>
                                </a>
                            </li>
                            <li class="nav__item" style="padding: 0 10px;" *ngFor="let item of arrayCollaboratorsCategory">
                                <a routerLink="../collaborators/{{item.postID}}">
                                    <i style="color: #019cce;font-size: 16px;margin-bottom: 10px;" class="fa fa-square-o"></i><span style="margin-left: 5px;color: #019cce;">{{item.postTitle}}</span>
                                </a>
                            </li>
                        </ul>
                    </li>
                    <li class="nav__item  has-dropdown">
                        <a data-toggle="dropdown" class="dropdown-toggle nav__item-link">Vacancy</a>
                        <ul class="dropdown-menu" style="
                        position: absolute;
                        left: auto !important;
                        right: 10px !important;">
                         <li class="nav__item" style="padding: 0 10px;">
                            <a routerLink="../Vacancy">
                                <i style="color: #019cce;font-size: 16px;margin-bottom: 10px;" class="fa fa-square-o"></i><span style="margin-left: 5px;color: #019cce;">All Scholarships</span>
                            </a>
                        </li>
                            <li class="nav__item" style="padding: 0 10px;" *ngFor="let item of arrayVecancyCategory">
                                <a routerLink="../vacancy/{{item.postID}}">
                                    <i style="color: #019cce;font-size: 16px;margin-bottom: 10px;" class="fa fa-square-o"></i><span style="margin-left: 5px;color: #019cce;">{{item.postTitle}}</span>
                                </a>
                            </li>
                        </ul>
                    </li>

                </ul>
                
            </div>
           
        </div>
    </nav>
</header>

<router-outlet (activate)="onActivate($event)"></router-outlet>
<footer class="footer bg-heading">
    <div class="footer-primary">
        <div class="container">

            <div class="row">

                <div class="col-sm-6 col-md-4 col-lg-2 footer-widget footer-widget-nav">
                    <h6 class="footer-widget__title">Research-Team</h6>
                    <div class="footer-widget__content">
                        <nav>
                            <ul class="list-unstyled">

                                <li *ngFor="let item of arrayTeamCategory">
                                    <a routerLink="../our-team/{{item.postID}}"> <i class="fas fa-long-arrow-alt-right mr-2"></i> {{item.postTitle}}</a>
                                </li>

                            </ul>
                        </nav>
                    </div>
                </div>
                <div class="col-sm-6 col-md-4 col-lg-2 footer-widget footer-widget-nav">
                    <h6 class="footer-widget__title">Research Projects</h6>
                    <div class="footer-widget__content">
                        <nav>
                            <ul class="list-unstyled">
                                <li *ngFor="let item of arrayProjectCategory"><a routerLink="../researchProjects/{{item.postID}}"><i class="fas fa-long-arrow-alt-right mr-2"></i>
                                        {{item.postTitle}}</a></li>

                            </ul>
                        </nav>
                    </div>
                </div>
                <div class="col-sm-6 col-md-4 col-lg-2 footer-widget footer-widget-nav">
                    <h6 class="footer-widget__title marginLTitle">Research-Topics</h6>
                    <div class="footer-widget__content">
                        <nav>
                            <ul class="list-unstyled marginLR">
                                <li *ngFor="let item of arrayResearchTopic">
                                    <a routerLink="../researchTopics/{{item.postID}}"> <i class="fas fa-long-arrow-alt-right mr-2"></i> {{item.postTitle}}
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>

                <div class="col-sm-6 col-md-4 col-lg-2 footer-widget footer-widget-nav">
                    <h6 class="footer-widget__title">Publications</h6>
                    <div class="footer-widget__content">
                        <nav>
                            <ul class="list-unstyled">
                                <li *ngFor="let item of arrayPublicationCategroy">
                                    <a routerLink="../publications/{{item.postID}}/{{item.postTitle}}"> <i class="fas fa-long-arrow-alt-right mr-2"></i> {{item.postTitle}}
                                    </a>
                                </li>

                            </ul>
                        </nav>
                    </div>
                </div>

                <div class="col-sm-6 col-md-4 col-lg-2 footer-widget footer-widget-nav">
                    <h6 class="footer-widget__title">Collaborators</h6>
                    <div class="footer-widget__content">
                        <nav>
                            <ul class="list-unstyled">
                                <li *ngFor="let item of arrayCollaboratorsCategory">
                                    <a routerLink="../collaborators/{{item.postID}}"> <i class="fas fa-long-arrow-alt-right mr-2"></i> {{item.postTitle}}
                                    </a>
                                </li>

                            </ul>
                        </nav>
                    </div>
                </div>

                <div class="col-sm-6 col-md-4 col-lg-2 footer-widget footer-widget-nav">
                    <h6 class="footer-widget__title">Vacancy</h6>
                    <div class="footer-widget__content">
                        <nav>
                            <ul class="list-unstyled" style="margin-right: -11px;">
                                <li *ngFor="let item of arrayVecancyCategory">
                                    <a routerLink="../vacancy/{{item.postID}}"> <i class="fas fa-long-arrow-alt-right mr-2"></i> {{item.postTitle}}
                                    </a>
                                </li>

                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="footer-secondary">
        <div class="container">
            <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-12 text-center">
                    <div class="footer-widget__content">
                        <img alt="logo" class="mb-30" src="{{logofooter}}">
                        <p class="color-gray mb-10" [innerHtml]="FooterDetails"></p>

                        <div style="text-align: center;" class="mb-10">
                            <ul class="social-icons list-unstyled mb-0" style="    display: -webkit-inline-box;">
                                <li><a href="{{fb_footer}}" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                                <li><a href="{{linkedin_footer}}" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                            </ul>
                        </div>
                    </div>

                    <span class="fz-14">&copy; {{year}} WCRG, All Rights Reserved. By </span>
                    <a class="fz-14 color-primary">WCRG.</a>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-12 text-center">
                    <a target="_blank" href="https://newtouch.dev">
                        <span class="fz-14">Powered by <span style="color: #fff;">NewTouch</span> Business Solutions </span>
                    </a>
                </div>
            </div>
        </div>
    </div>
</footer>


<button id="scrollTopBtn"><i class="fas fa-angle-double-up"></i></button>
