import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
const routes: Routes = [

  {
    path: '',
    loadChildren:()=> import('./components/home/home.module').then(m=>m.homeModule)
  },
  {
    path: 'home',
    loadChildren:()=> import('./components/home/home.module').then(m=>m.homeModule)
  },
  {
    path: 'contactUs',
    loadChildren:()=> import('./components/contact-us/contact-us.module').then(m=>m.contactUsModule)
  },
  {
    path: 'aboutUs',
    loadChildren:()=> import('./components/about-us/about-us.module').then(m=>m.aboutUsModule)
  },
  {
    path: 'aboutUs/:id',
    loadChildren:()=> import('./components/about-us/about-us.module').then(m=>m.aboutUsModule)
  },
  {
    path:'our-team',
    loadChildren:()=> import('./components/our-team/our-team.module').then(m=>m.OurTeamModule)

  },
  {
    path:'our-team/:id',
    loadChildren:()=> import('./components/our-team/our-team.module').then(m=>m.OurTeamModule)
  },
  {
    path:'our-team/OurTeamDetails/:id',
    loadChildren:()=> import('./components/our-team-details/our-team-details.module').then(m=>m.OurTeamDetailsModule)

  },
  {
    path:'OurTeamDetails/:id',
    loadChildren:()=> import('./components/our-team-details/our-team-details.module').then(m=>m.OurTeamDetailsModule)

  },
  {
    path:'Underway',
    loadChildren:()=> import('./components/underway/underway.module').then(m=>m.underwayModule),
    runGuardsAndResolvers: 'always'

  },
  {
    path:'Vacancy',
    loadChildren:()=> import('./components/vacancy/vacancy.module').then(m=>m.vacancyModule),
    runGuardsAndResolvers: 'always'
  },
  {
    path:'vacancy/:id',
    loadChildren:()=> import('./components/vacancy/vacancy.module').then(m=>m.vacancyModule),
    runGuardsAndResolvers: 'always'
  },
  {
    path:'collaborators',
    loadChildren:()=> import('./components/collaborators/collaborators.module').then(m=>m.CollaboratorsModule)

  },
  {
    path:'collaborators/:id',
    loadChildren:()=> import('./components/collaborators/collaborators.module').then(m=>m.CollaboratorsModule)
  },
  {
    path:'collaborators/CollaboratorsDetails/:id',
    loadChildren:()=> import('./components/collaborators-details/collaborators-details.module').then(m=>m.collaboratorsDetailsModule)
  },
  {
    path:'CollaboratorsDetails/:id',
    loadChildren:()=> import('./components/collaborators-details/collaborators-details.module').then(m=>m.collaboratorsDetailsModule)

  },
  {
    path:'publications',
    loadChildren:()=> import('./components/publications/publications.module').then(m=>m.publicationsModule)
  },
  {
    path:'publications/:id/:title',
    loadChildren:()=> import('./components/publications/publications.module').then(m=>m.publicationsModule)

  },
  {
    path:'researchTopics',
    loadChildren:()=> import('./components/research-topics/research-topic.module').then(m=>m.ResearchTopicModule)
  },
  {
    path:'researchTopics/:id',
    loadChildren:()=> import('./components/research-topics/research-topic.module').then(m=>m.ResearchTopicModule)
  },
  {
    path:'researchProjects',
    loadChildren:()=> import('./components/research-projects/research-projects.module').then(m=>m.ResearchProjectsModule)
  },

  {
    path:'researchProjects/:id',
    loadChildren:()=> import('./components/research-projects/research-projects.module').then(m=>m.ResearchProjectsModule),
    runGuardsAndResolvers: 'always'
  },
  {
    path:'vacancyDetails/:id',
    loadChildren:()=> import('./components/vacancy-details/vacancy-details.module').then(m=>m.vacancyModule),
    runGuardsAndResolvers: 'always'

  },
  {
    path:'vacancy/vacancyDetails/:id',
    loadChildren:()=> import('./components/vacancy-details/vacancy-details.module').then(m=>m.vacancyModule),
    runGuardsAndResolvers: 'always'
  },
  {
    path:'researchTopicDetails/:id',
    loadChildren:()=> import('./components/research-topic-details/research-topic-details.module').then(m=>m.ResearchTopicDetailsModule),
    runGuardsAndResolvers: 'always'
  },
  {
    path:'researchTopic/researchTopicDetails/:id',
    loadChildren:()=> import('./components/research-topic-details/research-topic-details.module').then(m=>m.ResearchTopicDetailsModule),
    runGuardsAndResolvers: 'always'
  },
  {
    path:'researchProjectDetails/:id',
    loadChildren:()=> import('./components/research-projects-details/research-projects-details.module').then(m=>m.ResearchProjectsDetailsModule)
  },
  {
    path:'researchProject/researchProjectDetails/:id',
    loadChildren:()=> import('./components/research-projects-details/research-projects-details.module').then(m=>m.ResearchProjectsDetailsModule)
  },
  {
    path: '404',
    loadChildren: ()=>import('./components/eroor404/error404.module').then(m=>m.Error404Module)
  },
  {
    path: '**',
    redirectTo: '/404'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
