import { LoadingRoutingoModule } from './loading.routing.module';
import { LoadingComponent } from './loading.component';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import {CommonModule} from '@angular/common';
@NgModule({
  imports: [
    LoadingRoutingoModule,
    HttpClientModule,
    CommonModule,
  ],
  declarations: [LoadingComponent],
  exports: [LoadingComponent]
})
export class LoadingModule { }
